import { useState } from "react";

const CompanyAccounts = (props)=> {
    const [activeTab, setActiveTab] = useState('address-details');
    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
      };
    const companyDetail = props?.user;
 return (
    <div
      className="modal fade"
      id="companyAccount"
      tabIndex="-1"
      aria-labelledby="companyAccount"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Profile Details</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="card card-new">
              <div class="d-flex payment-header">
                <div class="payment-header-left w-50">
                  <h2 class="mb-0">{companyDetail?.GSTDetails?.length > 0 ? 'Medium' : companyDetail?.GSTDetails?.length > 1 ? "High" : "Low"} Impact</h2>
                  {/* <p>
                    Last updated on <strong>{13 Jun 2024}</strong>
                  </p> */}
                </div>
                <div class="payment-header-right w-50 text-end  align-items-center">
                  <span class={`badge text-bg-${companyDetail?.GSTDetails?.length > 0 ? 'success' : companyDetail?.GSTDetails?.length > 1 ? "success" : "warning"} mt-3`}>{companyDetail?.GSTDetails?.length > 0 ? 'Good' : companyDetail?.GSTDetails?.length > 1 ? "Excellence" : "Fair"}</span>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="">
                  <h2 class="mb-0">{companyDetail?.LISTING_STATUS}</h2>
                  <p class="mb-0">Listed Status</p>
                </div>
                <div class="ps-5">
                  <h2 class="mb-0">{companyDetail?.GSTDetails?.length}</h2>
                  <p class="mb-0">Active GST</p>
                </div>
              </div>
            </div>
            {/* <div class="card card-new text-bg-warning">
              <div class="d-flex payment-header">
                <div style={{ marginRight: "8px", marginTop: "10px" }}>
                  <i
                    class="fas fa-exclamation-triangle"
                    style={{ "font-size": "40px", color: "#dc3545" }}
                  ></i>
                </div>
                <div class="payment-header-left w-60 ">
                  <h2 class="mb-0 fs-5 text-danger">Inconsistent Address</h2>
                  <p className="fs-5 ">
                    Your address is different across various accounts. Please
                    contact your bank to update it for a more accurate score.
                  </p>
                </div>
              </div>
            </div> */}
            {/* <h4>Basic Information : </h4> */}
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'address-details' ? 'active' : ''}`}
            id="pills-address-details-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-address-details"
            type="button"
            role="tab"
            aria-controls="pills-address-details"
            aria-selected={activeTab === 'address-details'}
            onClick={() => handleTabChange('address-details')}
          >
            Address Details
          </button>
        </li>
        {/* <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'phone-details' ? 'active' : ''}`}
            id="pills-phone-details-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-phone-details"
            type="button"
            role="tab"
            aria-controls="pills-phone-details"
            aria-selected={activeTab === 'phone-details'}
            onClick={() => handleTabChange('phone-details')}
          >
            Phone Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'email-details' ? 'active' : ''}`}
            id="pills-email-details-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-email-details"
            type="button"
            role="tab"
            aria-controls="pills-email-details"
            // aria-selected={activeTab === 'email-details'}
            // onClick={() => handleTabChange('email-details')}
          >
            Email Details
          </button>
        </li> */}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className={`tab-pane fade ${activeTab === 'address-details' ? 'show active' : ''}`}
          id="pills-address-details"
          role="tabpanel"
          aria-labelledby="pills-address-details-tab"
        >
          {companyDetail?.addressArray.map((item, index) => (
       <div key={index} className="d-flex flex-row align-items-start">
       <div className="flex-grow-1 ms-3">
         <p className="mb-0"><strong>Address Type</strong>:&nbsp;&nbsp;{item.addressType} </p>
         <p className="mb-0"><strong>Street Address</strong>:&nbsp;&nbsp;{item.streetAddress} </p>
         <p className="mb-0"><strong>City & State</strong>:&nbsp;&nbsp;{item.city} {item.state} </p>
         <hr />
       </div>
     </div>
          ))}
        </div>
        {/* <div
          className={`tab-pane fade ${activeTab === 'phone-details' ? 'show active' : ''}`}
          id="pills-phone-details"
          role="tabpanel"
          aria-labelledby="pills-phone-details-tab"
        > */}
          {/* {phones.map((phone, index) => (
            <div key={index} className="d-flex flex-row align-items-start">
       <div className="flex-grow-1 ms-3">
             <p><strong>Contact {index+1}</strong> : {phone.Number}</p>
             <hr/>
            </div>
            </div>
          ))} */}
        {/* </div> */}
        {/* <div
          className={`tab-pane fade ${activeTab === 'email-details' ? 'show active' : ''}`}
          id="pills-email-details"
          role="tabpanel"
          aria-labelledby="pills-email-details-tab"
        > */}
          {/* {emails.map((email, index) => (
          <div key={index} className="d-flex flex-row align-items-start">
       <div className="flex-grow-1 ms-3">
            <p><strong>Emails {index+1}</strong> : {email.EmailAddress}</p>
            <hr/>
            </div>
            </div>
          ))} */}
        {/* </div> */}
      </div>          
          </div>
        </div>
      </div>
    </div>
 )
}

export default CompanyAccounts;