import { useState } from "react";

const CompanyAgeModal = (props) => {
  const companyDetails = props?.user;
  const [yearsDifference, setYearsDifference] = useState({});
  // const [accounts, setAccounts] = useState([]);
  const calculateDateDifference = (dateString) => {
    // Regular expression to match date format "dd/mm/yyyy"
    const regex = /(\d{2}\/\d{2}\/\d{4})/;
    const match = dateString.match(regex);

    if (match) {
      const dateParts = match[0].split("/");
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
      const now = new Date();

      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();

      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years -= 1;
        months += 12;
      }

      return { years, months, days };
    }

    return { years: 0, months: 0, days: 0 };
  };
  const yearsMonth = calculateDateDifference(companyDetails?.IncorporationDate);

  return (
    <div
      className="modal fade"
      id="companyAgeModel"
      tabIndex="-1"
      aria-labelledby="companyAgeModel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Age Details</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className="modal-body">
            <div className="card card-new">
              <div className="d-flex payment-header">
                <div className="payment-header-left w-50">
                  <h2 className="mb-0">
                    {yearsMonth.years > 10
                      ? "High"
                      : yearsMonth.years > 2
                      ? "Medium"
                      : "Low"}{" "}
                    Impact
                  </h2>
                  {/* <p>
                    Last updated on <strong>13 Jun 2024</strong>
                  </p> */}
                </div>
                <div className="payment-header-right w-50 text-end  align-items-center">
                  <span className="badge text-bg-success mt-3">
                    {yearsMonth.years > 10 ? "Excellence" : "Fair"}
                  </span>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="">
                  <h2 className="mb-0">{companyDetails?.IncorporationDate}</h2>
                  <p className="mb-0">Incorporation Date</p>
                </div>
                <div className="ps-5">
                  <h2 className="mb-0">
                    {yearsMonth.years} y {yearsMonth.months} m{" "}
                  </h2>
                  <p className="mb-0">Age of Accounts</p>
                </div>
              </div>
            </div>
            <div className="card card-new text-bg-light">
              <div className="d-flex payment-header">
                <div style={{ marginRight: "8px", marginTop: "4px" }}>
                  <i
                    className="fas fa-thumbs-up text-info"
                    style={{ fontSize: "30px" }}
                  ></i>
                </div>
                <div className="payment-header-left w-60">
                  <h2 className="p-accounts mb-0 text-info">
                    {" "}
                    {yearsDifference.years > 10
                      ? "High Account Age"
                      : "Average Account Age"}
                  </h2>
                  <p>
                    Good Job ! You have a account for last{" "}
                    <strong>
                      {yearsMonth.years} years {yearsMonth.months} months
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            {/* <h4>Your Accounts</h4> */}

            {/* {accounts.map((item, index) => {
              const isClosed = item.Active !== "Yes";
              return (
                <div
                  className={`d-flex border-bottom pb-3 pt-3 ${
                    isClosed ? "blur" : ""
                  }`}
                >
                  <div className="flex-shrink-0">
                    <img
                      src="https://placehold.co/80x80/EEE/31343C"
                      alt="..."
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="mb-0">{item.BankName}</h4>
                    <p className="mb-0 text-muted">
                      {item.AccountType}: {item.AccountNumber}
                    </p>
                    <p className="mb-0 text-muted">
                      Status:{" "}
                      <strong className="text-primary">
                        {item.Active === "Yes" ? "Active" : "closed"}
                      </strong>
                    </p>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAgeModal;
