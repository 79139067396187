import ProfileUser from "./components/ProfileUser";
import "../../style.css";
import MainGraph from "./components/MainGraph";
import { useEffect, useState, useCallback, useMemo } from "react";
import PaymentModal from "./components/PaymentModal";
import AccountModal from "./components/AccountsModal";
import AgeModal from "./components/AgeModal";
import ProfileModal from "./components/ProfileModal";
import GSTUser from "./components/GSTUser";
import GSTPaymentModal from "./components/GSTPaymentModal";
import BarChart from "./components/CompanyGraph";
import CompanyProfileModal from "./components/CompanyProfile";
import CompanyAgeModal from "./components/CompanyAgeModal";
import CompanyAccounts from "./components/CompanyAccounts";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../features/dashboardSlice/dashboardSlice";
import axios from "axios";
import CryptoJS from 'crypto-js';
import http from "../../services/httpService";

const UserProfile = () => {
  const [active, setActive] = useState("");
  const [filingData, setFilingData] = useState([]);
  const [date, setDate] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [uid,setUID] = useState([""]);
  const data = useSelector((state) => state.dashboardSlice.data);
  const dispatch = useDispatch();
  const getID = useMemo(() => {
    const getUrlParameter = (name) => {
      const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
      const results = regex.exec(window.location.search);
      return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
    };
    const id = getUrlParameter('data');
    if (id) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(id, 'PrivatecourtProfileData');
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        console.log(decryptedData)
        const parsedData = JSON.parse(decryptedData);
        console.log(parsedData)
        if (Date.now() > parsedData.exp) {
          // console.error("The link has expired.");
          window.location.href = '/notFound';
          return null;
        }
        return parsedData.UID;
      } catch (error) {
        console.error("Failed to decrypt or parse the data:", error);
        return null;
      }
    }
    console.error("No data parameter found in URL.");
    return null;
  }, []);
  console.log(getID)
  const calculateDateDifference = (dateString) => {
    console.log("dateString: ", dateString);
    const regex = /(\d{2}-\d{2}-\d{4})/;
    const match = dateString.match(regex);
    if (match) {
      const dateParts = match[0].split("-");
      const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);

      const now = new Date();
      let years = now.getFullYear() - date.getFullYear();
      let months = now.getMonth() - date.getMonth();
      let days = now.getDate() - date.getDate();

      if (days < 0) {
        months -= 1;
        days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      }

      if (months < 0) {
        years -= 1;
        months += 12;
      }

      return { years, months, days };
    }

    return { years: 0, months: 0, days: 0 };
  };


  //   try {
  //     const response = await fetch("/data.json");
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const jsonData = await response.json();
      
  //     dispatch(setData(jsonData));

  //     const retailAccountDetails =
  //       jsonData.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0]
  //         .CIRReportData.RetailAccountDetails;

  //     const filterAccounts = retailAccountDetails.map((item) => ({
  //       history: item.History48Months,
  //     }));
  //     setFilingData(filterAccounts);

  //     const filterAccounts1 =
  //       jsonData.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0]
  //         .CIRReportData.RetailAccountsSummary.OldestAccount;
  //     setActive(
  //       jsonData.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0]
  //         .CIRReportData.RetailAccountsSummary.NoOfActiveAccounts
  //     );
  //     setDate(filterAccounts1);

  //     const activeCompanies = jsonData.companies_list.filter(
  //       (item) => item.companyStatus === "Active"
  //     );
  //     setCompanyData(activeCompanies);
  //   } catch (error) {
  //     alert("Something went wrong First"); // Handle error
  //   }
  // }, [dispatch]);
  const BasicDetailsVerified = () => {
    const { aadhaar, address, bank, phone, pan_number } = data;
    console.log(
      "aadhaar, address, bank, phone, pan_number",
      aadhaar,
      address,
      bank,
      phone,
      pan_number
    );
    const missingFields = [];
    if (!aadhaar) missingFields.push("aadhaar");
    if (!address) missingFields.push("address");
    if (!bank) missingFields.push("bank");
    if (!phone) missingFields.push("phone");
    if (!pan_number) missingFields.push("pan_number");

    if (missingFields.length > 0) {
      return {
        status: `The following fields are missing: ${missingFields.join(", ")}`,
        allFieldsPresent: false,
        missingFields: missingFields,
      };
    } else {
      return {
        status: "All fields are present.",
        allFieldsPresent: true,
        missingFields: [],
      };
    }
  };
  const VerifiedStatus = BasicDetailsVerified();
  useEffect(() => {
    if(getID){
      getDataValue(getID);
      setUID(getID);
    }
    
  }, [getID]);
  const getDataValue = async (getID) => {
    await http.post2("/readJson",{UID:getID})
    .then((res)=>{
      dispatch(setData(res.data)); 
      if(Object.values(res.data).length == 0){
          window.location.href = '/notFound'
      }
      const filterAccounts =
      res.data.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0].CIRReportData.RetailAccountDetails.map(
        (item) => ({
          history: item.History48Months,
        })
      );
    setFilingData(filterAccounts);
    const filterAccounts1 =
      res.data.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0]
        .CIRReportData.RetailAccountsSummary.OldestAccount;
    setActive(
      res.data.CIBILDetails.credit_report.CCRResponse.CIRReportDataLst[0]
        .CIRReportData.RetailAccountsSummary.NoOfActiveAccounts
    );
    setDate(filterAccounts1);
    const data = res.data.companies_list.filter(
      (item) => item.companyStatus == "Active"
    );
    setCompanyData(data);
    })
    .catch((err)=>{
      window.location.href = '/server-error'
      console.log(err);
    })
  }


  const combinedFilingData = filingData?.flatMap((item) =>
    Object.values(item).flat()
  );
  console.log("Combined Filling Data", combinedFilingData);
  const calculatePayment = () => {
    let totalcount = 0;
    let paidcount = 0;

    combinedFilingData.forEach((item) => {
      if (
        item.PaymentStatus == "STD" ||
        item.PaymentStatus == "000" ||
        item.PaymentStatus == "CLSD" ||
        item.PaymentStatus == "NEW"  ||
        item.PaymentStatus == "*"
      )  {
        paidcount++;
      }
      totalcount++;
    });

    let percent = (paidcount / totalcount) * 100;
    percent = percent.toFixed(2);
    return { percent, paidcount, totalcount };
  };
  const dateDifference = calculateDateDifference(date);
  return (
    <>
      <div className="main-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="right-wrapper">
                <div className="profile-business-info profile-business-info-2">
                  <div className="row">
                    <div className="col-lg-12">
                      <ProfileUser user={data} />
                    </div>
                    <div className="col-lg-12">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header details-analysis-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Details Analysis
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-lg-6">
                                  <ul className="list-unstyled">
                                    <li className="list-inline-item">
                                      {VerifiedStatus.allFieldsPresent ? (
                                        <i className="bi bi-patch-check-fill"></i>
                                      ) : (
                                        <i className="bi bi-patch-check text-muted"></i>
                                      )}
                                      &nbsp; Basic Details Verified
                                    </li>
                                    <li className="list-inline-item">
                                      <i
                                        className={`bi ${
                                          VerifiedStatus.allFieldsPresent
                                            ? "bi-patch-check-fill"
                                            : "bi-patch-check text-muted"
                                        }`}
                                      ></i>
                                      &nbsp;{VerifiedStatus.status}
                                    </li>
                                  </ul>
                                  <MainGraph
                                    cibil={data?.CIBILDetails?.credit_score}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <div className="pt-5">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="card border-0 shadow details-analysis-card">
                                          <div className="card-body">
                                            <h5 className="card-title mb-0">
                                              Payment
                                            </h5>
                                            <p className="card-text">
                                              Highly Impact
                                            </p>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#paymentmodal"
                                              className="stretched-link card-payment"
                                            ></a>
                                          </div>
                                          <div className="card-footer">
                                            <p style={{ color: "#fff" }}>
                                              Timely payments
                                            </p>
                                            <h3>
                                              {calculatePayment().percent}%
                                            </h3>
                                            <i className="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#paymentmodal"
                                              className="stretched-link card-payment"
                                            ></a>
                                             <PaymentModal /> 
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card border-0 shadow details-analysis-card">
                                          <div className="card-body">
                                            <h5 className="card-title mb-0">
                                              Age
                                            </h5>
                                            <p className="card-text">
                                              Highly Impact
                                            </p>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ageModel"
                                              className="stretched-link card-payment"
                                            ></a>
                                          </div>
                                          <div className="card-footer">
                                            <p style={{ color: "#fff" }}>
                                              Timely payments
                                            </p>
                                            <h3>
                                              {dateDifference.years > 15
                                                ? "90%"
                                                : dateDifference.years > 10
                                                ? "80%"
                                                : dateDifference.years > 5
                                                ? "60%"
                                                : "less than 60%"}
                                            </h3>
                                            <i className="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#ageModel"
                                              className="stretched-link card-payment"
                                            ></a>
                                            <AgeModal />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card border-0 shadow details-analysis-card">
                                          <div className="card-body">
                                            <h5 className="card-title mb-0">
                                              Accounts
                                            </h5>
                                            <p className="card-text">
                                              {active > 2
                                                ? "High"
                                                : active > 1
                                                ? "Medium"
                                                : "Low"}{" "}
                                              Impact
                                            </p>
                                          </div>
                                          <div className="card-footer">
                                            <p style={{ color: "#fff" }}>
                                              Timely payments
                                            </p>
                                            <h3>{active} Active</h3>
                                            <i className="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#accountsmodal"
                                              className="stretched-link card-accounts"
                                            ></a>
                                            <AccountModal
                                              PaymentStatus={calculatePayment()}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="card border-0 shadow details-analysis-card">
                                          <div className="card-body">
                                            <h5 className="card-title mb-0">
                                              Profile
                                            </h5>
                                            <p className="card-text">
                                              Low Impact
                                            </p>
                                          </div>
                                          <div className="card-footer">
                                            <p style={{ color: "#fff" }}>
                                              Timely payments
                                            </p>
                                            <h3>{active}</h3>
                                            <i className="bi bi-chevron-right"></i>
                                            <a
                                              href="javascript: void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#profileModal"
                                              className="stretched-link card-accounts"
                                            ></a>
                                            <ProfileModal active={active} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="companyData">
                      {companyData.map((item, index) => (
                        <div key={item.id || index} className="row">
                          <div className="col-lg-12">
                            <GSTUser user={item} />
                          </div>
                          <div className="col-lg-12">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header details-analysis-header">
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseOne${index}`}
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                  >
                                    Details Analysis
                                  </button>
                                </h2>
                                <div
                                  id={`collapseOne${index}`}
                                  className="accordion-collapse collapse show"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <ul className="list-unstyled">
                                          <li className="list-inline-item">
                                            <i className="bi bi-patch-check-fill"></i>{" "}
                                            Basic Details Verified
                                          </li>
                                          <li className="list-inline-item">
                                            <i className="bi bi-patch-check text-muted"></i>{" "}
                                            Need to Verify Address
                                          </li>
                                        </ul>
                                        <BarChart user={item} />
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="pt-5">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <div className="card border-0 shadow details-analysis-card">
                                                <div className="card-body">
                                                  <h5 className="card-title mb-0">
                                                    Payment
                                                  </h5>
                                                  <p className="card-text">
                                                    Highly Impact
                                                  </p>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#paymentmodal"
                                                    className="stretched-link card-payment"
                                                  ></a>
                                                </div>
                                                <div className="card-footer">
                                                  {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                                  <h6 style={{ color: "#fff" }}>
                                                    Check Status..
                                                  </h6>
                                                  <i className="bi bi-chevron-right"></i>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#gstpaymentmodal"
                                                    className="stretched-link card-payment"
                                                  ></a>
                                                  <GSTPaymentModal
                                                    companyData={companyData}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="card border-0 shadow details-analysis-card">
                                                <div className="card-body">
                                                  <h5 className="card-title mb-0">
                                                    Age
                                                  </h5>
                                                  <p className="card-text">
                                                    Highly Impact
                                                  </p>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#companyAgeModel"
                                                    className="stretched-link card-payment"
                                                  ></a>
                                                </div>
                                                <div className="card-footer">
                                                  {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                                  <h6>Check Age..</h6>
                                                  <i className="bi bi-chevron-right"></i>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#companyAgeModel"
                                                    className="stretched-link card-payment"
                                                  ></a>
                                                  <CompanyAgeModal
                                                    user={item}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="card border-0 shadow details-analysis-card">
                                                <div className="card-body">
                                                  <h5 className="card-title mb-0">
                                                    Accounts
                                                  </h5>
                                                  <p className="card-text">
                                                    Low Impact
                                                  </p>
                                                </div>
                                                <div className="card-footer">
                                                  {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                                  <h6 style={{ color: "#fff" }}>
                                                    Check Accounts..
                                                  </h6>
                                                  <i className="bi bi-chevron-right"></i>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#companyAccount"
                                                    className="stretched-link card-accounts"
                                                  ></a>
                                                  <CompanyAccounts
                                                    user={item}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <div className="card border-0 shadow details-analysis-card">
                                                <div className="card-body">
                                                  <h5 className="card-title mb-0">
                                                    Profile
                                                  </h5>
                                                  <p className="card-text">
                                                    Low Impact
                                                  </p>
                                                </div>
                                                <div className="card-footer">
                                                  {/* <p style={{color:"#fff"}}>Timely payments</p> */}
                                                  <h6 style={{ color: "#fff" }}>
                                                    Check Profile..
                                                  </h6>
                                                  <i className="bi bi-chevron-right"></i>
                                                  <a
                                                    href="javascript: void(0)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#companyprofile"
                                                    className="stretched-link card-accounts"
                                                  ></a>
                                                  <CompanyProfileModal
                                                    user={item}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
