import React from 'react';
// import '../../../style.css'

const ProfileImage = ({ imageUrl }) => {
  const profileImgStyle = {
    backgroundImage: `url(${"https://placehold.co/100x100/EEE/31343C"})`,
    // width: '100px',
    // height: '100px',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // borderRadius: '50%',
  };

  return <div className="profile-img" style={profileImgStyle}></div>;
};

console.log(ProfileImage)

const GSTProfileDetails = ({ name, Date, address, pan, CIN }) => {
  return (
    <div className="col-auto col-xl-3 col-lg-9 profile-details-col card-body py-0 align-self-center">
      <h5 className="card-title">{name}</h5>
      <ul className="list-unstyled mb-0">
        {/* <li><strong>Company:</strong> </li> */}
        <li><strong>Incorporation Date:</strong> {Date}</li>

        <li><strong>Address:</strong>{address}</li>
        <li><strong>PAN :</strong> {pan}</li>
        <li><strong>CIN:</strong>{CIN}</li>
      </ul>
    </div>
  );
};

const VerificationDetails = ({ verifications }) => {
  return (
    <ul className="list-unstyled">
      {verifications.map((verification, index) => (
        <li key={index} className="list-inline-item">
          <i className={`bi ${verification.verified ? 'bi-patch-check-fill' : 'bi-patch-check text-muted'}`}></i> {verification.label}
        </li>
      ))}
    </ul>
  );
};

const GSTUser = (props) => {
  const verifications = [
    { label: 'CIN', verified: (props.user.PAN ==="N/A" || props.user.PAN ==="") ? false : true },
    // { label: 'udyam Aadhar', verified: (props.user.aadhaar ==="N/A" || props.user.aadhaar ==="") ? false : true },
    { label: 'Address', verified: (props.user.Address ==="N/A" || props.user.Address ==="") ? false : true },
    // { label: 'Bank', verified: (props.user.bank ==="N/A" || props.user.bank ==="") ? false : true },
    // { label: 'Phone', verified: (props.user.phone ==="N/A" || props.user.phone ==="") ? false : true },
    { label: 'Email', verified: (props.user.Email ==="N/A" || props.user.Email ==="") ? false : true },
    { label: 'PAN', verified: (props.user.PAN ==="N/A" || props.user.PAN ==="") ? false : true },
    // { label: 'gstFiling', verified: (props.user.courtCase?.count === "0" || props.user.courtCase?.status ==="No cases") ? true : false },
  ];

  return (
    <div className="card personal-long-card card-new">
      <div className="row">
        {/* <div className="col-auto profile-col">
          <ProfileImage imageUrl="https://placehold.co/100x100/EEE/31343C" />
        </div> */}
        {/* <div className="col-auto"> */}
          <GSTProfileDetails
            name={props.user.CompanyName}
            Date={props.user.IncorporationDate}
            address={props.user.Address}
            pan={props.user.PAN}
            CIN={props.user?.CIN}
          />
        {/* </div> */}
        <div className="col-xl-6 right-list">
          <VerificationDetails verifications={verifications} />
        </div>
      </div>
    </div>
  );
};

export default GSTUser;
