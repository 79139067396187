import React from 'react';
// import CreditScoreGauge from './CreditScoreGauge';
import './graph.css';
import Graph from './Graph';

const MainGraph = (props) => {
  return (
    <div className="main-graph">
      <h1>Credit Score Gauge</h1>
      <Graph score={props.cibil} />
    </div>
  );
};

export default MainGraph;