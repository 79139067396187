import nofound from '../../asset/icons/404.jpg'
const NotFound = () =>{
//     We're sorry, but the link you have used is no longer valid.
// It seems the link has expired. For security reasons, this link was time-sensitive and is no longer accessible.

// If you need to access this information again, please request a new link or reach out to our support team for further assistance.

// Thank you for your understanding!
    return (
        <div class="container">
            <div class="row h-auto">
                <div className="col-lg-6 align-self-center">
                    <h1 className='md' data-translate="adc-main-heading"> We're sorry, but the link you have used is no longer valid.</h1>
                    {/* <p data-translate="adc-title-12">It seems the link has expired. For security reasons, this link was time-sensitive and is no longer accessible.</p>    
                    <p data-translate="adc-title-12"> If you need to access this information again, please request a new link or reach out to our support team for further assistance.</p>                 */}
                    <p data-translate="adc-title-12">Thank you for your understanding!</p>
                </div>
                <div className="col-lg-6 align-self-center">
                    <img className="img-fluid" src={nofound} alt="No found"/>
                </div>
            </div>
        </div>  
    )
}
export default NotFound;
        

