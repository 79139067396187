
import React, { useEffect, useRef, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";

const Graph = ({ score }) => {
  const [dimensions, setDimensions] = useState({ width: 400, height: 300 });
  const containerRef = useRef(null);
  useEffect(() => {
    const updateDimensions = () => {
      // console.log("called")
      if (containerRef.current) {
        const { offsetWidth } = containerRef.current;
        if (window.innerWidth < 500) {
          setDimensions({
            width: offsetWidth * 0.9,
            height: offsetWidth * 0.45,
          });
        } else {
          setDimensions({
            width: offsetWidth,
            height: offsetWidth * 0.58,
          });
        }
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const numericScore = !isNaN(Number(score)) ? Number(score) : 0;

  const { width, height } = dimensions;
  const validWidth = !isNaN(width) ? width : 400;
  const validHeight = !isNaN(height) ? height : 300;
  return (
    <div
      ref={containerRef}
      className="speedometer-container"
      style={{ textAlign: "center", marginTop: "40px" }}
    >
      <ReactSpeedometer
        forceRender={true}
        value={numericScore}
        minValue={300}
        maxValue={900}
        segments={4}
        segmentColors={["#FF0000", "#FFA500", "#00FF00", "#008000"]}
        needleColor="#000000"
        startColor="#FF0000"
        endColor="#008000"
        textColor="#000000"
        ringWidth={60}
        width={window.innerWidth < 500 ? dimensions.width : 400}
        height={window.innerWidth < 500 ? dimensions.width : 250}
        />
      <div
        className="speedometer-labels"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <span
          style={{ color: "#FF0000", fontSize: "20px", fontWeight: "bold" }}
        >
          Poor
        </span>
        <span
          style={{ color: "#FFA500", fontSize: "20px", fontWeight: "bold" }}
        >
          Average
        </span>
        <span
          style={{ color: "#00FF00", fontSize: "20px", fontWeight: "bold" }}
        >
          Good
        </span>
        <span
          style={{ color: "#008000", fontSize: "20px", fontWeight: "bold" }}
        >
          Excellent
        </span>
      </div>
    </div>
  );
};

export default Graph;
