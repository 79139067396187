import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const BarChart = (props) => {
  const gstArray = props?.user?.GSTDetails || [];
  const combinedTurnOver = gstArray.reduce((acc, gst) => {
    gst.gstTurnOver5year.forEach(({ pan_estimated_total, year }) => {
      if (!acc[year]) {
        acc[year] = 0;
      }
      acc[year] += parseFloat(pan_estimated_total);
    });
    return acc;
  }, {});

  const resultArray = Object.keys(combinedTurnOver).map((year) => ({
    year,
    totalEstimated: combinedTurnOver[year],
  }));

  const labels = resultArray.map((item) => item.year);
  const dataValues = resultArray.map((item) => parseFloat(item.totalEstimated));

  // Create the data object for Chart.js
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Company Turnover Each Year",
        backgroundColor: "#2f4b7c",
        borderColor: "rgb(255, 99, 132)",
        data: dataValues,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to adjust with the container size
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      {/* Set the height to control the vertical space the chart takes */}
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
