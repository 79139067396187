import React, { useEffect, useState } from "react";
// import PaymentHistory from '../PaymentMore/PaymentCalender';
import FillingCalender from "../PaymentMore/FillingCalender";

const GSTPaymentModal = (props) => {
  const [selectedGST, setSelectedGST] = useState(null);
  const [combinedFiling, setCombinedFiling] = useState([]);
  const companyArray = props.companyData;

  const combineEFiledList = (data) => {
    return data?.reduce((acc, curr) => {
      return acc.concat(curr.response.EFiledlist);
    }, []);
  };


  const handleGSTChange = (event) => {
    const gstin = event.target.value;
    const gstDetails = companyArray[0].GSTDetails.find(
      (item) => item.gstin === gstin
    );
    setCombinedFiling(combineEFiledList(gstDetails?.gstReturn3Year));
    setSelectedGST(gstDetails);
  };

  useEffect(() => {}, [combinedFiling]);
  return (
    <div
      className="modal fade"
      id="gstpaymentmodal"
      tabIndex="-1"
      aria-labelledby="paymentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">GST Payment</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className="modal-body">
          <select 
              className="form-select" 
              aria-label="Default select example" 
              onChange={handleGSTChange}
              defaultValue=""  // Setting default value
            >
              <option value="" disabled>Select GST as per state</option>
              {companyArray[0].GSTDetails.map((gst, index) => (
                <option key={index} value={gst.gstin}>
                  {gst.gstin} - {gst.place}
                </option>
              ))}
            </select>
            <div className="card card-new mt-3">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    Total GST:{" "}
                    <strong>
                      {Math.floor(
                        selectedGST?.gstTurnOver5year?.[0]?.gst_filed_total
                      )}{" "}
                      Rs. (Financial year :{" "}
                      {selectedGST?.gstTurnOver5year?.[0]?.year})
                    </strong>
                  </p>
                  <p className="mb-md-0">
                    Register Date:{" "}
                    <strong>
                      {selectedGST?.DOR ? selectedGST?.DOR : "N/A"}
                    </strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Last Filing:{" "}
                    <strong>
                      {combinedFiling?.[0]?.dof
                        ? combinedFiling?.[0]?.dof
                        : "N/A"}
                    </strong>
                  </p>
                  <p className="mb-md-0">
                    Status:{" "}
                    <strong>
                      {selectedGST?.gstActive ? selectedGST?.gstActive : "N/A"}{" "}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <h5>Details Monthly Wise Filing:</h5>
            {combinedFiling?.length > 0 ? (
              <FillingCalender gstFiling={combinedFiling} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSTPaymentModal;
